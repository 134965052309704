let Drug = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取药品列表数据
     * @param {any} success
     * @param {any} error
     */
    this.Drugs = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Drug/Search/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.mzDrugs = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Drug/SearchCooperation/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
 * 获取中药方剂搜索列表数据
 * @param searchId
 * @param categoryId
 * @param pageIndex
 * @param keyWord
 * @param success
 * @param error
 * @constructor
 */
    this.searchHerbals = function (searchId, categoryId, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription/Search';
        // if (searchId) {
        //     url += `/${searchId}`;
        // }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }

        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
 * 获取中西药分类列表数据
 * @param {any} success
 * @param {any} error
 */
    this.DrugCategories = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Drug/DrugCategories/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.mzDrugCategories = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Drug/DrugCooperationCategories/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    // 处方详情
    this.GetDetail = function (patientId, patientDiagnoseId, auditStatus, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetPatientPrescription'
        if (patientId) {
            url += `/${patientId}`;
        }
        if (patientDiagnoseId) {
            url += `/${patientDiagnoseId}`;
        }
        if (auditStatus) {
            url += `/${auditStatus}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.GetPrescriptionDetail = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetDetail/' + param
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.Compositions = function (name,category ,success, error) {
        var url = ServiceBaseAddress + `/api/Drug/Manager/Compositions/${name}/${category}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.Categorys = function (success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription/Categories';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.pertDetail = function (id, searchId, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription';
        if (id) {
            url += `/${id}`;
        }
        if (searchId) {
            url += `/${searchId}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getHerbalPrescriptionDetail = function (hashKey, success, error) {
      var url = ServiceBaseAddress + `/api/HerbalPrescription/${hashKey}`;
      TokenClient.Get(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data);
            success(jsonObj);
          }
        },
        error
      );
    };
    this.GetStateList = function (prescriptionType, userId, organitionId, auditStatus, startTime, endTime, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetState';
        if (prescriptionType) {
            url += `/${prescriptionType}`;
        }
        if (userId) {
            url += `/${userId}`;
        }
        if (organitionId) {
            url += `/${organitionId}`;
        }
        if (auditStatus || auditStatus==0) {
            url += `/${auditStatus}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
 * 获取中药方剂详情数据
 * @param id
 * @param searchId
 * @param keyWord
 * @param success
 * @param error
 * @constructor
 */
    this.SavePrescription = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取药品详情数据
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Drug/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Drug }